import loadable from '@loadable/component'

export default [
  {
    id: '@budget-write',
    reducer: () => import('./budget-write.reducer'),
    path: '/budget-write',
    exact: true,
    ref: '/',
    onload: () => import('./budget-write-view'),

    onfirst: app => {
      app.invokeService('@common:get:powers')
    },
  },
  {
    point: '@@components',
    namespace: '@budget-write',
    onload: () => [{ key: 'BudgetWriteComment', component: () => import('./layers/BudgetWriteCommentPopup') }]
  },
  {
    point: '@@layers',
    prefix: '@budget-write',
    onload: () => import('./layers')
  },
  {
    resource: '@budget-write',
    value: {
      ['elements/budget-write-detail']: loadable(() => import('./elements/budget-write-detail'))
    }
  }
  // {
  //   point: '@@menus',
  //   onload: () => [
  //     {
  //       id: 'budget-write',
  //       pId: 'financial',
  //       // permissions: ['SYS_ADMIN'],
  //       powers: ['BUDGETWRITE'],
  //       weight: 5.1,
  //       label: '预算编制',
  //       href: '/budget-write',
  //       icon: 'menu-loan'
  //     }
  //   ]
  // }
]
